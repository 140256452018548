// .brands_border {
//   display: flex;
//   justify-content: space-between;
//   border-bottom: 1px solid #eee;
//   align-items: center;
//   margin-bottom: 0.5rem !important;
//   border: 0 !important;
//   box-sizing: border-box;
// }

// .search_button {
//   display: inline-block;
//   font-weight: 400;
//   line-height: 1.5;
//   color: #6b7078;
//   text-align: center;
//   text-decoration: none;
//   vertical-align: middle;
//   cursor: pointer;
//   -webkit-user-select: none;
//   user-select: none;
//   background-color: transparent;
//   border: 1px solid transparent;
//   padding: 0.375rem 0.75rem;
//   font-size: 0.813rem;
//   border-radius: 0.25rem;
//   transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
//     border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
// }
@media (prefers-reduced-motion: reduce) {
  .btn {
    transition: none;
  }
}
.btn:hover {
  color: white;
}
.btn-check:focus + .btn,
.btn:focus {
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(94, 132, 195, 0.25);
}
.btn:disabled,
.btn.disabled,
fieldset:disabled .btn {
  pointer-events: none;
  opacity: 0.65;
}
